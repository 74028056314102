/** THREE JS */

import './style.css?ver=1.0'
import * as THREE from 'three'
import * as dat from 'lil-gui'
import * as jQuery from 'jquery'
import { OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js'
import { BufferGeometry, DoubleSide } from 'three'
import gsap from 'gsap'
// import gsapCore from 'gsap/gsap-core'
import { ScrollTrigger } from "gsap/ScrollTrigger.js"
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js"
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)
// import { Lensflare, LensflareElement } from 'three/examples/jsm/objects/Lensflare.js'
import { RenderPass} from 'three/examples/jsm/postprocessing/RenderPass.js'
import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer.js'
import {UnrealBloomPass} from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'


console.log(ScrollToPlugin);
// console.log(gsapCore);
console.log(OrbitControls);



  console.log(ScrollTrigger);
/**
 * Debug
 */
// const gui = new dat.GUI()

/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
// scene.background = new THREE.Color().setHSL( 0.51, 0.4, 0.01 );
// scene.fog = new THREE.Fog( scene.background, 3500, 15000 );

// Environment map
const cubeTextureLoader = new THREE.CubeTextureLoader()

const environmentMapTexture = cubeTextureLoader.load([
    '/textures/cube/px.jpg',
    '/textures/cube/nx.jpg',
    '/textures/cube/py.jpg',
    '/textures/cube/ny.jpg',
    '/textures/cube/pz.jpg',
    '/textures/cube/nz.jpg'
])

// scene.background = environmentMapTexture;
// scene.environment = environmentMapTexture;

/**
 * Lights and lensflare
 */

// Ambient
scene.add( new THREE.AmbientLight( 0xFF7A00 ) );


// PointLight
// const sphere0 = new THREE.SphereGeometry( 0.1, 16 );

// const pointLightA = new THREE.PointLight( 0xffffff, 3 );
// pointLightA.add( new THREE.Mesh( sphere0, new THREE.MeshBasicMaterial({color: 0xffffff } ) ) )
// pointLightA.layers.enable(1)

// const pointLightB = new THREE.PointLight( 0x444444, 2 );
// pointLightB.add( new THREE.Mesh( sphere0, new THREE.MeshBasicMaterial({color: 0xFFFFFF } ) ) )
// pointLightB.layers.enable(1)
// pointLightB.position.set (0, 0, 18)

// const pointLightB2 = new THREE.PointLight( '#ffa9b7', 3 );
// pointLightB.add( new THREE.Mesh( sphere0, new THREE.MeshBasicMaterial({color: 0xb4fb03 } ) ) )
// pointLightB.layers.enable(1)
// pointLightB.position.set (0, 0, -22)

// const pointLightC = new THREE.PointLight( 0xFF7A00, 8 );
// pointLightC.add( new THREE.Mesh( sphere0, new THREE.MeshBasicMaterial({color: 0xFF7A00 } ) ) )
// pointLightC.layers.enable(1)
// pointLightB.position.set (3, 3, 3)

// scene.add(pointLightA, pointLightB, pointLightB2, pointLightC)



/**
 * 
 * 
 * World */
// world



const starGeometry = new THREE.SphereGeometry(0.03, 10);
const starMaterial = new THREE.MeshBasicMaterial( { color: 0xffffff } );
starMaterial.color.convertSRGBToLinear();

for ( let i = 0; i < 300; i ++ ) {
    const starMesh = new THREE.Mesh( starGeometry, starMaterial );

    starMesh.position.x = 80 * ( 2.0 * Math.random() - 1.0 );
    starMesh.position.y = 80 * ( 2.0 * Math.random() - 1.0 );
    starMesh.position.z = 80 * ( 2.0 * Math.random() - 1.0 );

    starMesh.rotation.x = Math.random() * Math.PI;
    starMesh.rotation.y = Math.random() * Math.PI;
    starMesh.rotation.z = Math.random() * Math.PI;

    // starMesh.layers.enable(1); uncomment if bloom

    starMesh.matrixAutoUpdate = false;
    starMesh.updateMatrix();

    starMesh.geometry.computeVertexNormals();
    // scene.add( starMesh ); uncomment if stars needed

}



/**
 * Objects
 */

/**
 * Spheres
 */


// Texture loader (needed for the matcap texture)
const textureLoader = new THREE.TextureLoader()


//  Carbon Texture
// const carbonTexture = textureLoader.load('/textures/carbon/Carbon.png')
// carbonTexture.repeat.x = 40
// carbonTexture.repeat.y = 40
// carbonTexture.wrapS = THREE.RepeatWrapping
// carbonTexture.wrapT = THREE.RepeatWrapping

// const diffuse = textureLoader.load( '/textures/carbon/Carbon.png' );
// diffuse.encoding = THREE.sRGBEncoding;
// diffuse.wrapS = THREE.RepeatWrapping;
// diffuse.wrapT = THREE.RepeatWrapping;
// diffuse.repeat.x = 40;
// diffuse.repeat.y = 40;

// const normalMap = textureLoader.load( '/textures/carbon/Carbon_Normal.png' );
// normalMap.wrapS = THREE.RepeatWrapping;
// normalMap.wrapT = THREE.RepeatWrapping;


// const carbon1material = new THREE.MeshPhysicalMaterial()
// carbon1material.metalness = 0.7
// carbon1material.roughness = 0.36
// carbon1material.clearcoat = 1
// carbon1material.clearcoatRoughness = 0.1
// carbon1material.reflectivity = 1
// carbon1material.envMap = environmentMapTexture
// carbon1material.map = diffuse
// carbon1material.normalMap = normalMap
// carbon1material.wireframe = false

// gui
// gui.add(carbon1material, 'metalness').min(0).max(1).step(0.0001)
// gui.add(carbon1material, 'roughness').min(0).max(1).step(0.0001)
// gui.add(carbon1material, 'clearcoat').min(0).max(1).step(0.0001)
// gui.add(carbon1material, 'clearcoatRoughness').min(0).max(1).step(0.0001)
// gui.add(carbon1material, 'reflectivity').min(0).max(1).step(0.0001)

const sphere1 = new THREE.Mesh(
new THREE.SphereGeometry(100, 100),
    new THREE.MeshBasicMaterial({ color: '#ff0000', wireframe: false })
    // carbon1material
)
// sphere1.geometry.computeVertexNormals();
sphere1.position.set(0,0,0);

const sphere2material = new THREE.MeshBasicMaterial({ color: '#FF7A00', wireframe: false, transparent: true, side: DoubleSide })
sphere2material.color.convertSRGBToLinear();

const sphere2 = new THREE.Mesh(
    new THREE.SphereGeometry(0.2, 30),
    sphere2material
    
)
// sphere2.color.convertSRGBToLinear();
sphere2.layers.enable(1);

scene.add(sphere2, sphere1)


/**
 * Torus
 */


// Torus Geometries
const torusgeometryA = new THREE.TorusGeometry(5, 0.8, 16, 100)
const torusgeometryB = new THREE.TorusGeometry(2, 0.01, 16, 100)

const matcapTexture = textureLoader.load('/textures/matcap/matcap1.png')

const torusmaterialA = new THREE.MeshMatcapMaterial({
     color: 0x111111,
    // // map: carbonTexture,
    matcap: matcapTexture,
    // side: DoubleSide,
    // flatShading: true,
    // wireframe: false
    transparent: true
})
// const torusmaterial = new THREE.MeshPhysicalMaterial({ roughness: 0.5,
//     clearcoat: 1.0,
//     clearcoatRoughness: 0.1,
//     map: diffuse,
//     normalMap: normalMap})
const torusmaterialB = new THREE.MeshBasicMaterial({ color: 0xFF7A00, wireframe: false, side: DoubleSide, transparent: true})
torusmaterialB.color.convertSRGBToLinear();




// Elements torusGroupA
const torusAA = new THREE.Mesh(torusgeometryA, torusmaterialA)
const torusAB = new THREE.Mesh(torusgeometryA, torusmaterialA)
const torusAC = new THREE.Mesh(torusgeometryA, torusmaterialA)
const torusAD = new THREE.Mesh(torusgeometryA, torusmaterialA)
const torusAE = new THREE.Mesh(torusgeometryA, torusmaterialA)
const torusAF = new THREE.Mesh(torusgeometryA, torusmaterialA)


// Elements torusGroupB
const torusBA = new THREE.Mesh(torusgeometryB, torusmaterialB)
const torusBB = new THREE.Mesh(torusgeometryB, torusmaterialB)
const torusBC = new THREE.Mesh(torusgeometryB, torusmaterialB)
const torusBD = new THREE.Mesh(torusgeometryB, torusmaterialB)
const torusBE = new THREE.Mesh(torusgeometryB, torusmaterialB)
const torusBF = new THREE.Mesh(torusgeometryB, torusmaterialB)

// torusAA.rotation.x += 0
// torusAB.rotation.x += Math.PI / 6
// torusAC.rotation.x += Math.PI / 6 * 2
// torusAD.rotation.x += Math.PI / 6 * 3
// torusAE.rotation.x += Math.PI / 6 * 4
// torusAF.rotation.x += Math.PI / 6 * 5

torusAA.scale.z -= 0.8
torusAB.scale.z -= 0.8
torusAC.scale.z -= 0.8
torusAD.scale.z -= 0.8
torusAE.scale.z -= 0.8
torusAF.scale.z -= 0.8





// Torus Goup A
const torusGroupA = new THREE.Group()
torusGroupA.add( torusAA );
torusGroupA.add( torusAB );
torusGroupA.add( torusAC );
torusGroupA.add( torusAD );
torusGroupA.add( torusAE );
torusGroupA.add( torusAF );
torusGroupA.rotation.y += Math.PI / 2

scene.add(torusGroupA)


// Torus Group B
const torusGroupB = new THREE.Group()
torusGroupB.add( torusBA )
torusGroupB.add( torusBB )
torusGroupB.add( torusBC )
torusGroupB.add( torusBD )
torusGroupB.add( torusBE )
torusGroupB.add( torusBF )

torusGroupB.rotation.y += Math.PI / 3
torusBA.layers.enable(1)
torusBB.layers.enable(1)
torusBC.layers.enable(1)
torusBD.layers.enable(1)
torusBE.layers.enable(1)
torusBF.layers.enable(1)
scene.add(torusGroupB)



/**
 * Sizes
 */

//  let width = box.offsetWidth;
//  let height = box.offsetHeight;

const sizes = {
    // width: window.innerWidth,
    // height: window.innerHeight
    width: canvas.offsetWidth,
    height: canvas.offsetHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    // sizes.width = window.innerWidth
    // sizes.height = window.innerHeight
    sizes.width = canvas.offsetWidth,
    sizes.height = canvas.offsetHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})


/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 20)
camera.position.z = 1
scene.add(camera)
    // Gsap

    // gsap.to(camera.position, {duration: 5, delay: 7, ease: "power4", z: 130 })
    // gsap.to(camera.position, {duration: 30, delay: 0, y: Math.PI * 2 })
    // gsap.to(camera.position, {duration: 6, delay: 4.5, ease: "power2", z: 7, y: 0.5})
    // gsap.to(camera.rotation, {duration: 10, delay: 5.5, ease: "power2", z: Math.cos * 3})


/**
 * Controls (Uncomment for development) 
 */
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true


/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true,
    antialias: false
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

// GSAP
// gsap.to(torusGroupA.position, {duration: 1, delay: 2, x: 2})
// gsap.to(torusGroupA.position, {duration: 1, delay: 3, x: 0})


// RenderPass adding Postprocessing and filter
const renderScene = new RenderPass(scene, camera);
const composer = new EffectComposer(renderer);
composer.addPass(renderScene);

const bloomPass = new UnrealBloomPass(
    new THREE.Vector2(window.innerWidth, window.innerHeight),
    1.6,
    0.2,
    0.1
);
composer.addPass(bloomPass);

 bloomPass.strength = 5
// bloomPass.radius = 5
// bloomPass.threshold = 0.1

renderer.toneMapping = THREE.CineonToneMapping
renderer.toneMappingExposure = 1





/** 
 *  Scroll animation
 */

/**
 * Scroll
 */
 let scrollY = window.scrollY
 let scrollV = window.scrollY / sizes.height



/**
 *  GSAP Timeline
 * 
 */

  // gsap.to(".main-lead-text-body-scrollmore", {
  //   duration: 1,
  //   ease: 'power3.inOut',
  //   opacity: 0,
  //   yoyo: true,
  //   repeat: -1
  // })


/**
  * 
  * 00 STEP ZERO: INTRO
  * 
  */

    gsap.to(camera.position, {
      duration: 1,
      ease: "power3.inOut",
      z: 3
      })


/**
  * 
  * 01 STEP ONE: First Scroll, zoom out, hide title
  * 
  */


  gsap.registerPlugin(ScrollTrigger);
  console.log(ScrollTrigger);
  var target = document.getElementById("trigger-1")

  var tl0 = gsap.timeline({
    scrollTrigger: {
      trigger: target,
      start: "top 98%",
      end: "top 98%",
      toggleActions: 'play none none reverse',
      markers: false,
      scrub: false
  }})

  tl0.addLabel('step0-0')
  tl0.to("#lead-step1-1", { opacity: 0, duration: 1 }, 'step1-0')
  tl0.to("#lead-step1-2", { opacity: 0, duration: 1.5 }, 'step1-0')
  // tl0.to("#lead", { opacity: 0, duration: 1.5 }, 'step1-0')


            // var tl = gsap.timeline({
            //     scrollTrigger: {
            //     trigger: triggerOne,
            //     start: "top 35%", // when the top of the trigger hits the top of the viewport
            //     end: "top 20%", // end after 
            //     toggleActions: "play none none reverse",
            //     // scrub: 0, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            //     markers: false
            //     // position: 'step1-1',
            //     // snap: {
            //     //   snapTo: "#cases-body", // snap to the closest label in the timeline
            //     //   directional: true,
            //     //   duration: {min: 1, max: 2}, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
            //     //   delay: 0, // wait 0.2 seconds from the last scroll event before doing the snapping
            //     //   ease: "power4.inOut" // the ease of the snap animation ("power3" by default)
            //     // }
            //     }, repeat: 2, yoyo: true
            // })

            // NOTIZEN AN MICH: TIMELINE GEHT WOHL NICHT. ALLES MIT FROMTO ANIMATIONEN AUFBAUEN UND DELAYS UND NACHEINANDER SCHALTEN.
            //https://rahuldkjain.github.io/blog/gsap-animation-series/1-familiar-with-basic-functions/
  
      var tl1 = gsap.timeline({
        scrollTrigger: {
          trigger: target,
          start: "top 80%",
          end: "top 80%",
          toggleActions: 'play none none reverse',
          markers: false,
          scrub: false
      }})
   
      tl1.addLabel('step1-0')
      tl1.to(camera.position, {
        duration: 1,
        delay: 0.3,
        ease: "power4.inOut",
        z: 11,
        y: -0.2
      }, 'step1-0')
      tl1.to("#lead", { opacity: 0, duration: 1.5 }, 'step1-0')
      // tl1.to("#lead-step1-1", { opacity: 0, duration: 1 }, 'step1-0')
      // tl1.to("#lead-step1-2", { opacity: 0, duration: 1.5 }, 'step1-0')
      // tl1.to("#lead", { opacity: 0, duration: 1.5 }, 'step1-0')
        // window.addEventListener('scroll', () =>
        //     {
        //     var progress = tl1.progress(); 
        //     console.log(progress); });
        /// HIER

/**
  * 
  * 02 STEP TWO: Unfold Accelerator
  * 
  */

  var tl2 = gsap.timeline({
    scrollTrigger: {
      trigger: target,
      start: "top 50%",
      end: "top 50%",
      toggleActions: 'play none none reverse',
      markers: false,
      scrub: false
}})

      tl2.addLabel("step2-0");
      tl2.to("#step2-1", { opacity: 1, duration: 0.2, delay: 1, ease: "power4.inOut" });
      // Step three
      tl2.to("#a-slide-1", {opacity: 1, duration: 0.1})
      tl2.addLabel("step3-0")
      tl2.to(torusAB.rotation, {duration: 1, delay: 1.2, ease: "power1", x: Math.PI / 6}, "step2-0")
      tl2.to(torusAC.rotation, {duration: 1, delay: 1.3, ease: "power1", x: Math.PI / 6 * 2}, "step2-0")
      tl2.to(torusAD.rotation, {duration: 1, delay: 1.4, ease: "power1", x: Math.PI / 6 * 3}, "step2-0")
      tl2.to(torusAE.rotation, {duration: 1, delay: 1.5, ease: "power1", x: Math.PI / 6 * 4}, "step2-0")
      tl2.to(torusAF.rotation, {duration: 1, delay: 1.6, ease: "power1", x: Math.PI / 6 * 5}, "step2-0")
      tl2.to(torusBB.rotation, {duration: 1, delay: 1.3, ease: "power1", y: Math.PI / 6}, "step2-0")
      tl2.to(torusBC.rotation, {duration: 1, delay: 1.4, ease: "power1", y: Math.PI / 6 * 2}, "step2-0")
      tl2.to(torusBD.rotation, {duration: 1, delay: 1.5, ease: "power1", y: Math.PI / 6 * 3}, "step2-0")
      tl2.to(torusBE.rotation, {duration: 1, delay: 1.6, ease: "power1", y: Math.PI / 6 * 4}, "step2-0")
      tl2.to(torusBF.rotation, {duration: 1, delay: 1.7, ease: "power1", y: Math.PI / 6 * 5}, "step2-0")
      tl2.addLabel("step2-2");



/**
  * 
  * 03 SLIDE ONE: Economic plan
  * 
  */

    var tl3 = gsap.timeline({
      scrollTrigger: {
        trigger: "#trigger-3",
        start: "top 50%",
        end: "top 50%",
        toggleActions: 'play none none reverse',
        markers: false,
        scrub: false
   }})
    // Slide 1
        tl3.addLabel("slide-1")
        tl3.to("#a-slide-2", {opacity: 1, duration: 0.1})
        // tl3.to("#a-slide-2", {opacity: 0, delay: 0, duration: 0.1}, 'slide-1')
        // tl3.to("#a-slide-3", {opacity: 0, delay: 0, duration: 0.1}, 'slide-1')
        // tl3.to("#a-slide-4", {opacity: 0, delay: 0, duration: 0.1}, 'slide-1')
        // tl3.to("#a-slide-5", {opacity: 0, delay: 0, duration: 0.1}, 'slide-1')
        tl3.to(sphere2.scale, {x:4, y:4, z:4, duration: 0.5, ease: 'power4.inOut'}, 'slide-1')
        tl3.to(torusAA.material, {opacity: 0.2, duration: 0.3, ease: 'power4.inOut'}, 'slide-1')
        tl3.to(torusBB.material, {opacity: 0.05, duration: 0.3, ease: 'power4.inOut'}, 'slide-1')
  


/**
  * 
  * 04 SLIDE TWO: XXX
  * 
  */

  var tl4 = gsap.timeline({
    scrollTrigger: {
      trigger: "#trigger-4",
      start: "top 50%",
      end: "top 50%",
      toggleActions: 'play none none reverse',
      markers: false,
      scrub: false
 }});
      tl4.addLabel("slide-2")
      // tl4.to("#a-slide-1", {opacity: 0, delay: 0, duration: 0.1}, 'slide-2')
      // tl4.to("#a-slide-3", {opacity: 0, delay: 0, duration: 0.3}, 'slide-2')
      // tl4.to("#a-slide-4", {opacity: 0, delay: 0, duration: 0.3}, 'slide-2')
      // tl4.to("#a-slide-5", {opacity: 0, delay: 0, duration: 0.3}, 'slide-2')
      tl4.to("#a-slide-3", {opacity: 1, duration: 0.1})
      tl4.to(sphere2.scale, {x:1, y:1, z:1, duration: 0.6, ease: 'power3'}, 'slide-2')
      tl4.to(sphere2.material, {opacity: 0.05, duration: 0.6, ease: 'power3'}, 'slide-2')
      tl4.to(torusBB.material, {opacity: 1, duration: 0.3, ease: 'power4'}, 'slide-2')
      tl4.to(torusAA.material, {opacity: 0, duration: 0.5, ease: 'power4'}, 'slide-2')
      tl4.to(torusGroupB.scale, {x: 1.8, y:1.8, z:1.8, duration: 1, ease: 'power3.inOut'}, 'slide-2')


 /**
  * 
  * 05 SLIDE THREE: XXX
  * 
  */

    var tl5 = gsap.timeline({
      scrollTrigger: {
        trigger: "#trigger-5",
        start: "top 50%",
        end: "top 50%",
        toggleActions: 'play none none reverse',
        markers: false,
        scrub: false
   }});
        // Slide 3
        tl5.addLabel("slide-3")
        // tl5.to("#a-slide-2", {opacity: 0, delay: 0, duration: 0.1}, 'slide-3')
        // tl5.to("#a-slide-1", {opacity: 0, delay: 0, duration: 0.01}, 'slide-3')
        // tl5.to("#a-slide-4", {opacity: 0, delay: 0, duration: 0.01}, 'slide-3')
        // tl5.to("#a-slide-5", {opacity: 0, delay: 0, duration: 0.01}, 'slide-3')
        tl5.to("#a-slide-4", {opacity: 1, duration: 0.1}, 'slide-3')
        tl5.to(sphere2.material, {opacity: 1, duration: 0.6, ease: 'power3'}, 'slide-3')
        tl5.to(torusBB.material, {opacity: 0.05, duration: 0.3, ease: 'power4'}, 'slide-3')
        tl5.to(torusAA.material, {opacity: 0, wireframe: true, duration: 0.1, ease: 'power1'}, 'slide-3')
        tl5.to(torusAA.material, {opacity: 1, wireframe: true, duration: 0.6, delay: 0.1, ease: 'power4'}, 'slide-3')
        tl5.to(torusGroupB.scale, {x: 1, y:1, z:1, duration: 1, ease: 'power3.inOut'}, 'slide-3')



 /**
  * 
  * 06 SLIDE FOUR: XXX
  * 
  */

    var tl6 = gsap.timeline({
      scrollTrigger: {
        trigger: "#trigger-6",
        start: "top 50%",
        end: "top 50%",
        toggleActions: 'play none none reverse',
        markers: false,
        scrub: false
   }});
        tl6.addLabel("slide-4")
        // tl6.to("#a-slide-3", {opacity: 0, delay: 0, duration: 0.1}, 'slide-4')
        // tl6.to("#a-slide-1", {opacity: 0, delay: 0, duration: 0.01}, 'slide-4')
        // tl6.to("#a-slide-2", {opacity: 0, delay: 0, duration: 0.01}, 'slide-4')
        // tl6.to("#a-slide-5", {opacity: 0, delay: 0, duration: 0.01}, 'slide-4')
        tl6.to("#a-slide-5", {opacity: 1, duration: 0.1})
        tl6.to(torusBB.material, {opacity: 1, duration: 0.6, ease: 'power4.inOut'}, 'slide-4')
        tl6.to(torusAA.material, {opacity: 0, wireframe: false, duration: 0.2}, 'slide-4')
        tl6.to(torusAA.material, {opacity: 1, wireframe: false, delay: 0.2, duration: 0.5}, 'slide-4')


 /**
  * 
  * 07 SLIDE FIVE: XXX
  * 
  */
  
    var tl7 = gsap.timeline({
      scrollTrigger: {
        trigger: "#trigger-7",
        start: "top 50%",
        end: "top 50%",
        toggleActions: 'play none none reverse',
        markers: false,
        scrub: false
   }});
        tl7.addLabel("slide-5")
        // tl7.to("#a-slide-5", {opacity: 1, duration: 0.1}, 'slide-5')
        // tl7.to("#a-slide-4", {opacity: 0, delay: 0, duration: 0.1}, 'slide-5')
        // tl7.to("#a-slide-1", {opacity: 0, delay: 0, duration: 0.01}, 'slide-5')
        // tl7.to("#a-slide-2", {opacity: 0, delay: 0, duration: 0.01}, 'slide-5')
        // tl7.to("#a-slide-3", {opacity: 0, delay: 0, duration: 0.01}, 'slide-5')


 /**
  * 
  * 08 HIDE ALL
  * 
  */
  
  var tl8 = gsap.timeline({
    scrollTrigger: {
      trigger: "#trigger-8",
      start: "top 50%",
      end: "top 50%",
      toggleActions: 'play none none reverse',
      markers: false,
      scrub: false
 }});
      tl8.addLabel("accelerator-end")
      // tl8.to("#a-slide-5", {opacity: 0, delay: 0, duration: 0.2},  'accelerator-end')
      tl8.to(camera.position, {
        duration: 2,
        ease: "power4.inOut",
        z: -20,
        y: 2
        })



    /**
     * 09 Slides METHOD
     */ 
    var method1 = gsap.timeline({
          scrollTrigger: {
            trigger: "#method-trigger-1",
            start: "top 100%",
            end: "top 20%",
            toggleActions: 'play reverse play reset',
            // toggleActions: 'play none none reverse',
            markers: false,
            scrub: false
       }});
       gsap.to(".main-section-method-slider", {opacity: 0})
       method1.to(".main-section-method-argument--01", {opacity: 1, duration: 0.2, position: 1})
       method1.to("#method-slider-01", {opacity: 1, duration: 0.2, position: 1})
   
    var method2 = gsap.timeline({
          scrollTrigger: {
            trigger: "#method-trigger-2",
            start: "top 90%",
            end: "top 40%",
            toggleActions: 'play reverse play reset',
            markers: false,
            scrub: false
       }});
    
       method2.to(".main-section-method-argument--02", {opacity: 1, duration: 0.2, position: 1})
       method2.to("#method-slider-02", {opacity: 1, duration: 0.2, position: 1})
   
       var method3 = gsap.timeline({
          scrollTrigger: {
            trigger: "#method-trigger-3",
            start: "top 100%",
            end: "top 20%",
            toggleActions: 'play reverse play reset',
            markers: false,
            scrub: false
       }});
    
       method3.to(".main-section-method-argument--03", {opacity: 1, duration: 0.2, position: 1})
       method3.to("#method-slider-03", {opacity: 1, duration: 0.2, position: 1})
   

      //   gsap.to(window, {duration: 0.3,
      //     scrollTo:"#cases",
      //     scrollTrigger: {
      //       trigger: "#trigger-8",
      //       start: "top 50%",
      //       end: "top 50%",
      //       toggleActions: 'play none none none',
      //       markers: false,
      //       scrub: false
      //  }});
      /*
      SCROLL TO NEXT SCENE
      gsap.to(window, { scrollTrigger: {
              trigger: "#trigger-7",
              start: "top 50%",
              end: "top 50%",
              toggleActions: 'play play none none',
              markers: false,
              scrub: false
         }, scrollTo:"#cases"});
      tl8.to('#accelerator-slides', {opacity: 0, delay: 0, duration: 0.01},  'accelerator-end') */


// var master = gsap.timeline();
//     master.add(stepTwoTL()) //overlap by 1 second
//           .add(slideOneTL()) //overlap by 1 second
//           .add(slideTwoTL()) //overlap by 1 second
//           .add(slideThreeTL()) //overlap by 1 second
//           .add(slideFourTL()) //overlap by 1 second
//           .add(slideFiveTL()); //overlap by 1 second


//  window.addEventListener('scroll', () =>
// {
//     camera.position. = scrollY;
    
//     } )


// Listen to Scroll Event --> Legacy 
//  window.addEventListener('scroll', () =>
// {
//     var pageY = Math.round((window.pageYOffset / sizes.height) * 10000) / 10000 ;
//     scrollY = window.scrollY
//     console.log('pageY ', pageY);   
//     if (pageY > scrollV){
//        // downscroll code
//       var scrollD = 1;
//                 // Step 1 hide lead
//               if(scrollV >= 0.001 && scrollV <= 0.3 ){
//               // tl.play();
//                console.log("Play1-1");
              
//                } else { 
//                // tl.reverse();
//                // tl.play("step1-3");
//               }
    
//     } else {
//        // upscroll code
//        var scrollD = 0;
      
//         // tl.reverse();
//          console.log("Play1-1 up");
     

//     }
//     console.log('Scrolldirection: ', scrollD)
//     scrollV = Math.round((scrollY / sizes.height) * 1000) / 1000
//     const newSection = Math.round(scrollY / sizes.height)
//     // console.log('Current Section: ', currentSection)
//     // console.log('New Section: ', newSection)
//     console.log('ScrollV ', scrollV)
   
  // // Step 1 hide lead
  // if(scrollV >= 0.01 ){
  //   tl.play("step1-1");
  //   console.log("Play1-1");
  //   } else { 
  //     // tl.reverse();
  //     // tl.play("step1-3");
  //    }
  // Step 1-2
  // if(scrollV >= 0.022 && scrollV <= 0.03 ){
  //   tl.play("step1-2");
  //   tl.pause("step1-3");
  //   } else { 
  //     tl.reverse();
  //     tl.play("step1-3");
  //     tl.pause("step1-2");
  //    }
  // // Step 2
  //   if(scrollV >= 0.03  ){
  //     tl.play("step2-0");
  //     tl.pause("step2-3");
  //     } else { 
     
  //       tl.play("step2-3");
  //       tl.pause("step2-0");
  //      }



  /*
  if(scrollV >= 0.02 && scrollV <= 1.2){ gsap.to("#step1-2", { opacity: 0, duration: 0.5 })
    } else { gsap.to("#step1-2", { opacity: 1, duration: 0.5 }) }
// Step 2 show Sections
if(scrollV >= 0.021 && scrollV <= 3.2){ gsap.to("#step2-1", { opacity: 1, duration: 0.3, ease: "power4.inOut" })
} else { gsap.to("#step2-1", { opacity: 0, duration: 0.3 }) }

// Step 3 show process 1
if(scrollV >= 0.3 && scrollV <= 0.5)
    {
        gsap.to(camera.position,  {duration: 3, delay: 0, ease: "power4.inOut", z: 13, reverse: 0 })
        gsap.to(torusAB.rotation, {duration: 1.5, delay: 0, ease: "power1", x: Math.PI / 6})
        gsap.to(torusAC.rotation, {duration: 1.5, delay: 0.6, ease: "power1", x: Math.PI / 6 * 2})
        gsap.to(torusAD.rotation, {duration: 1.5, delay: 0.9, ease: "power1", x: Math.PI / 6 * 3})
        gsap.to(torusAE.rotation, {duration: 1.5, delay: 1.2, ease: "power1", x: Math.PI / 6 * 4})
        gsap.to(torusAF.rotation, {duration: 1.5, delay: 1.5, ease: "power1", x: Math.PI / 6 * 5})

        gsap.to(torusBB.rotation, {duration: 1.5, delay: 0, ease: "power1", y: Math.PI / 6})
        gsap.to(torusBC.rotation, {duration: 1.5, delay: 0.7, ease: "power1", y: Math.PI / 6 * 2})
        gsap.to(torusBD.rotation, {duration: 1.5, delay: 1, ease: "power1", y: Math.PI / 6 * 3})
        gsap.to(torusBE.rotation, {duration: 1.5, delay: 1.3, ease: "power1", y: Math.PI / 6 * 4})
        gsap.to(torusBF.rotation, {duration: 1.5, delay: 1.5, ease: "power1", y: Math.PI / 6 * 5})
  
        //or to scroll to the element with the ID "#someID":
        // gsap.to(window, {duration: 2, scrollTo:"#a-slide-1"});
    }  else {
    
    }

*/

// });

jQuery(".gsap-scrollto-accelerator").on('click', function(){
  gsap.to(window, {duration: 2, scrollTo:"#accelerator-header"});
});

// jQuery(function(){
//   gsap.to(window, {duration: 2, scrollTo:"body"});
// })


/**
 * GSAP Animations
 */

//  var mainSectionSlides = gsap.utils.toArray('.main-section-slide');

//  mainSectionSlides.forEach((mainSectionSlide) => {
   
//    gsap.from(mainSectionSlide, { autoAlpha: 0,
//      scrollTrigger: {
//          trigger:mainSectionSlide,
//          start: 'top center+=200',
//          scrub: true,
//          end: '+=50',
//          pin: true,
//          markers: false
//      }
//  })

//  gsap.to(mainSectionSlide, { autoAlpha: 0,
//   scrollTrigger: {
//       trigger:mainSectionSlide,
//       start: 'top center-=200',
//       scrub: true,
//       end: '+=300',
//       markers: false
//   }
// })
 
    // .to(mainSectionSlide, {autoAlpha:1});
  //  });




/**
 * Animation Loops
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    // Move Camera according to scroll
      // camera.position.z = 3 + (scrollY / sizes.height) * 1.2


    // Animate Group
    torusGroupA.rotation.x += Math.sin(elapsedTime * Math.PI / 10) / 100
    torusGroupA.rotation.y += Math.cos(elapsedTime * Math.PI / 10) / 100
    torusGroupB.rotation.x += Math.cos(elapsedTime * Math.PI / 10) / 100
    torusGroupB.rotation.y += Math.sin(elapsedTime * Math.PI / 10) / 100
    // sphere1.rotation.z += Math.cos(deltaTime * Math.PI / 10) / 500
    // sphere1.rotation.y += Math.sin(deltaTime * Math.PI / 10) / 500
    // gsap.to(torusGroupA.position, {duration: 1, delay: 3, y: -2, repeat: true})

    // Animate PointLight
    // pointLightA.position.x += Math.sin(elapsedTime * Math.PI / 3) / 15
    // pointLightA.position.y += Math.cos(elapsedTime * Math.PI / 3) / 15
    // pointLightA.position.z += Math.sin(elapsedTime * Math.PI / 3) / 15

    // pointLightB.position.x += Math.cos(elapsedTime * Math.PI / 3) / 15
    // pointLightB.position.y += Math.sin(elapsedTime * Math.PI / 3) / 15
    // pointLightB.position.z += Math.sin(elapsedTime * Math.PI / 3) / 15

    // pointLightB2.position.x += Math.cos(elapsedTime * Math.PI / 3) / 15
    // pointLightB2.position.y += Math.sin(elapsedTime * Math.PI / 3) / 15
    // pointLightB.position.z += Math.sin(elapsedTime * Math.PI / 3) / 15

    // pointLightC.position.x += Math.cos(elapsedTime * Math.PI / 2.5) / 15
    // pointLightC.position.y += Math.sin(elapsedTime * Math.PI / 2.5) / 15
    // pointLightB.position.z += Math.sin(elapsedTime * Math.PI / 3) / 15
 

    // Animate Camera
    // camera.rotation.y = Math.PI;
  
    // Update controls Enable when needed
    // controls.update()

    // Render
    // renderer.render(scene, camera)
    // composer.render(); // render the postprocessed scene
    renderer.autoClear = false; // turn false for bloom
    renderer.clear();
    renderer.outputEncoding = THREE.sRGBEncoding; // maybe not needed
    
    camera.layers.set(1);
    composer.render();
    
    renderer.clearDepth();
    
    camera.layers.set(0);
    // RENDER Wieder aktivieren wenn Three JS weitergemacht weren soll
    renderer.render(scene, camera);

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)

    // observer + log + stop render
// const statusElem = document.querySelector('.status');

// const onScreen = new Set();
// const intersectionObserver = new IntersectionObserver((entries) => {
//   entries.forEach(entry => {
//     if (entry.isIntersecting) {
//       onScreen.add(entry.target);
//       start();
//       console.log('render has been started');
//     } else {
//       onScreen.delete(entry.target);
//       stop();
//       console.log('render has been halted');
//         }     
//   });
//   statusElem.textContent = onScreen.size
//     ? `on screen: ${[...onScreen].map(e => e.textContent).join(', ')}`
//     : 'none';
// });

// document.querySelectorAll('.webgl').forEach(elem => intersectionObserver.observe(elem));


}

tick();












/** 
 * 
 * 
 * 
 * 
 * 
 * UI Interactions (Jquery for now)
 * 
 * 
 * 
 * 
 *  */
/*  */

(function($) {




/* SLIDESHOWS */
var $slider = $(".slider");
var $slides = $slider.find(".slider-item");
var $navPrev = $(".go-prev");
var $navNext = $(".go-next");
var $startAutoplay = $(".start-autoplay");
var $stopAutoplay = $(".stop-autoplay");
var slidesNum = $slides.length;
var prevSlideID = null;
var currentSlideID = 0;
var isAnimating = false;
var isAutoPlay = false;

function init() {
	gsap.set($slides, {
		// left: "-100%",
    opacity: "0"
	});
	$navPrev.on("click", gotoPrevSlide);
	$navNext.on("click", gotoNextSlide);
	$startAutoplay.on("click", startAutoPlay);
	$stopAutoplay.on("click", stopAutoPlay);
	gotoSlide(0, 0);
 

}
function gotoPrevSlide() {
	var slideToGo = currentSlideID - 1;
	if (slideToGo <= -1) {
		slideToGo = slidesNum - 1;
	}
	stopAutoPlay();
	gotoSlide(slideToGo, 3, "prev");
}

function gotoNextSlide() {
	var slideToGo = currentSlideID + 1;
	if (slideToGo >= slidesNum) {
		slideToGo = 0;
	}
	stopAutoPlay();
	gotoSlide(slideToGo, 4, "next");
}

function gotoSlide(slideID, _zeit, _direction) {
	if (!isAnimating) {
		isAnimating = true;
		prevSlideID = currentSlideID;
		currentSlideID = slideID;
		var $prevSlide = $slides.eq(prevSlideID);
		var $currentSlide = $slides.eq(currentSlideID);
		var zeit = 4;
		if (_zeit !== null) {
			zeit = _zeit;
		}
		var direction = "next";
		if (_direction != null) {
			direction = _direction;
		}
		if (direction == "next") {
			gsap.to($prevSlide, {
				// left: "-100%",
        opacity: "0",
        duration: 4,
        ease: "power1.inOut"
			});
			gsap.fromTo($currentSlide, {
				// left: "100%",
        opacity: "0",
        duration: 4,
        ease: "power1.inOut"
			}, {
				// left: "0",
        opacity: "1",
        duration: 4,
        ease: "power1.inOut"
			});
		} else {
			gsap.to($prevSlide, {
				// left: "100%",
        opacity: "0",
        duration: 4,
        ease: "power1.inOut"
			});
      
			gsap.fromTo($currentSlide, {
				// left: "-100%",
        opacity: "0",
        duration: 4,
        ease: "power1.inOut"
			}, {
				// left: "0",
        opacity: "1",
        duration: 4,
        ease: "power1.inOut"
			});
		}
		gsap.delayedCall(zeit, function() {
			isAnimating = false;
		});
	}
}

function play(){
  gotoNextSlide();
  gsap.delayedCall(4, play);
}

function startAutoPlay(immediate) {
	if (immediate != null) {
		immediate = false;
	}
    
	if (immediate) {
		gotoNextSlide();
	}
	gsap.delayedCall(5, play);
}


$(function() {
  startAutoPlay();
})

function stopAutoPlay() {
  isAutoPlay = false;
	// gsap.killDelayedCallsTo(play);
}
init();


$(function() {
 gsap.to("main", {opacity: 1, duration: 3})
})






    $('.nav-hamburger').on("click",function() {
    $("#nav-container").toggle(500);
        })
    $('.main-section-flexbox-header').on("click",function() {
    $(this).siblings().toggle();
    $(this).children('.main-section-flexbox-header-plus').toggleClass('n90deg');
    $(this).siblings('.main-section-flexbox-body').children('.two-col-grid').toggleClass('opacity1');
    })

/*
 $(window).on("scroll", function() {
        // if ( $('body').scrollTop() > $('#accelerator-scene-start').position.top ) {
        //   $('#accelerator-scene').addClass('fixed');
        //   console.log ("Bind");
        // }
    
        // if ( $('body').scrollTop() > $('#accelerator-scene-end').position.top ) {
        //   $('#accelerator-scene').removeClass('fixed');
        //   console.log ("Release");
        // }
  
        if ( $('body').scrollTop() > $('.section-b').position.top ) {
          $('.menu-items').removeClass('selected');
          $('.menu-b').addClass('selected');
          // console.log('selected');
        }
      
           })



/* Bind Scroll 


     
            $(window).bind('scroll', function() {
              var divs = document.getElementsByClassName("main-section-slide");
              var selectionDiv = document.getElementById("MySecondDiv");
              
              /* Console */
              // console.log(
              //   'Window',
              //   $(window).scrollTop(),
              //   'Accelerator Scene Start',
              //   $('#accelerator-scene-start').offset().top,
              //   $('#accelerator-scene-stop').offset().top,
              //   'Section:',
              //   $('.main-section-slide').offset().top,
              //   divs );
              /*
            var vHeight = $(window).height() -10;
            var acceleratorstart = $('#accelerator-scene-start').offset().top;
            var acceleratorstop = $('#accelerator-scene-stop').offset().top;


              if ($(window).scrollTop() > acceleratorstart && $(window).scrollTop() < acceleratorstop) {
                $('.accelerator-scene').addClass('fixed');
                console.log('accelerator scene fixed');
              }
              else {
                $('.accelerator-scene').removeClass('fixed');
                // console.log('Scroll-release');
            }

            if ($(window).scrollTop()+vHeight-100 > $('#a-slide-1').offset().top) { 
              $('#a-slide-1').addClass('opacity1');
              console.log('add');
             }
            else {
              $('#a-slide-1').removeClass('opacity1');
            }

      


           }); */
          })(jQuery)